import React, { FunctionComponent, Fragment } from "react";
import { Container, Row, Col } from "react-grid-system";
import { RouteComponentProps } from "@reach/router";
import { useIntl } from "react-intl";
import { graphql } from "gatsby";

import Preview from "../components/common/Preview";
import { Layout } from "../components";

import styles from "../styles/policies";
import * as CookieConsent from "vanilla-cookieconsent";
import Button from "../components/common/Button";

interface CookiesPageProps extends RouteComponentProps {
  data: {
    prismicCookiesPage: {
      data: {
        cookies_title: { text: string };
        cookies_content: Array<{
          content_title: { text: string };
          content_paragraph: { html: string };
        }>;
        privacy_title: { text: string };
        privacy_content: Array<{
          content_title: { text: string };
          content_paragraph: { html: string };
        }>;
        anti_bribery_policy_title: { text: string };
        anti_bribery_policy_content: Array<{
          content_title: { text: string };
          content_paragraph: { html: string };
        }>;
        report_communication_policy_title: { text: string };
        report_communication_policy_content: Array<{
          content_title: { text: string };
          content_paragraph: { html: string };
        }>;
        report_communication_title: { text: string };
        report_communication_content: Array<{
          content_title: { text: string };
          content_paragraph: { html: string };
        }>;
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
    background: {
      publicURL: string;
    };
  };
}

const CookiesPage: FunctionComponent<CookiesPageProps> = ({ data }) => {
  const { formatMessage } = useIntl();
  const { Padding, PolicyTitle, ContentTitle, ContentParagraph } = styles;

  return (
    <Layout
      metaTitle={
        data.prismicCookiesPage.data.title ||
        formatMessage({ id: "pages.policies.meta-title" })
      }
      metaDescription={
        data.prismicCookiesPage.data.description ||
        formatMessage({ id: "pages.policies.meta-description" })
      }
      metaImage={data.prismicCookiesPage.data.image}
      metaURL={data.prismicCookiesPage.data.canonical_url?.url}
    >
      <Padding
        style={{
          backgroundPosition:
            "right -500px top -500px, left -500px bottom -500px",
          backgroundImage: `url(${data.background.publicURL}), url(${data.background.publicURL})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col id="cookies" xs={12} style={{ marginBottom: "120px" }}>
              <PolicyTitle>
                {data.prismicCookiesPage.data.cookies_title.text}
              </PolicyTitle>
              {data.prismicCookiesPage.data.cookies_content.map(
                (content, index) => (
                  <Fragment key={`cookies#${index}`}>
                    <ContentTitle>{content.content_title.text}</ContentTitle>
                    <ContentParagraph
                      dangerouslySetInnerHTML={{
                        __html: content.content_paragraph.html,
                      }}
                    />
                  </Fragment>
                )
              )}
            </Col>
            <Col id="privacy" xs={12} style={{ marginBottom: "120px" }}>
              <PolicyTitle>
                {data.prismicCookiesPage.data.privacy_title.text}
              </PolicyTitle>
              {data.prismicCookiesPage.data.privacy_content.map(
                (content, index) => (
                  <Fragment key={`cookies#${index}`}>
                    <ContentTitle>{content.content_title.text}</ContentTitle>
                    <ContentParagraph
                      dangerouslySetInnerHTML={{
                        __html: content.content_paragraph.html,
                      }}
                    />
                  </Fragment>
                )
              )}
            </Col>
            <Col id="briberypolicy" xs={12}>
              <PolicyTitle>
                {data.prismicCookiesPage.data.anti_bribery_policy_title.text}
              </PolicyTitle>
              {data.prismicCookiesPage.data.anti_bribery_policy_content.map(
                (content, index) => (
                  <Fragment key={`cookies#${index}`}>
                    <ContentTitle>{content.content_title.text}</ContentTitle>
                    <ContentParagraph
                      dangerouslySetInnerHTML={{
                        __html: content.content_paragraph.html,
                      }}
                    />
                  </Fragment>
                )
              )}
            </Col>
            <Col id="reportcommunicationpolicies" xs={12}>
              <PolicyTitle>
                {
                  data.prismicCookiesPage.data.report_communication_policy_title
                    .text
                }
              </PolicyTitle>
              {data.prismicCookiesPage.data.report_communication_policy_content.map(
                (content, index) => (
                  <Fragment key={`cookies#${index}`}>
                    <ContentTitle>{content.content_title.text}</ContentTitle>
                    <ContentParagraph
                      dangerouslySetInnerHTML={{
                        __html: content.content_paragraph.html,
                      }}
                    />
                  </Fragment>
                )
              )}
            </Col>
            <Col id="reportcommunication" xs={12}>
              <PolicyTitle>
                {data.prismicCookiesPage.data.report_communication_title.text}
              </PolicyTitle>
              {data.prismicCookiesPage.data.report_communication_content.map(
                (content, index) => (
                  <Fragment key={`cookies#${index}`}>
                    <ContentTitle>{content.content_title.text}</ContentTitle>
                    <ContentParagraph
                      dangerouslySetInnerHTML={{
                        __html: content.content_paragraph.html,
                      }}
                    />
                  </Fragment>
                )
              )}
            </Col>
            <Col xs={12}>
              <PolicyTitle>Manage Cookies</PolicyTitle>
              <Button onClick={() => CookieConsent.showPreferences()}>
                Cookies Settings
              </Button>
            </Col>
          </Row>
        </Container>
      </Padding>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicCookiesPage(lang: { eq: $lang }) {
      data {
        cookies_title {
          text
        }
        cookies_content {
          content_title {
            text
          }
          content_paragraph {
            html
          }
        }
        privacy_title {
          text
        }
        privacy_content {
          content_title {
            text
          }
          content_paragraph {
            html
          }
        }
        anti_bribery_policy_title {
          text
        }
        anti_bribery_policy_content {
          content_title {
            text
          }
          content_paragraph {
            html
          }
        }
        report_communication_policy_title {
          text
        }
        report_communication_policy_content {
          content_title {
            text
          }
          content_paragraph {
            html
          }
        }
        report_communication_title {
          text
        }
        report_communication_content {
          content_title {
            text
          }
          content_paragraph {
            html
          }
        }
        canonical_url {
          url
        }
        description
        title
        image {
          alt
          url
        }
      }
    }
    background: file(
      sourceInstanceName: { eq: "static" }
      relativePath: { eq: "images/Background-single-transparent.png" }
    ) {
      publicURL
    }
  }
`;

export default Preview(CookiesPage);
