import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentWrapper = styled.div<{ background: string }>`
  width: calc(100vw - 432px);
  background-color: lightgray;
  background-image: ${props => `url(${props.background})`};
  display: flex;
  flex-direction: column;
  padding-top: 144px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 90px);
`;

const ContentHeader = styled.div`
  width: 100%;
  min-width: 753px;
  height: 150px;
  padding: 0px 150px 0px 100px;
  margin-top: 78px;
`;
const HeaderText = styled.h2<{ orange?: string | boolean; theme?: any }>`
  font-family: Roboto;
  font-weight: normal;
  font-size: 42px;
  color: ${props =>
    props.orange
      ? props.theme.colors.decunifyOrange
      : props.theme.colors.black};
  margin: 0px;
  line-height: 1.3;
`;

const ContentTextWrapper = styled.div`
  width: 57%;
  height: calc(100% - 238px);
  min-height: 384px;
  max-height: calc(100% - 238px);
  background-color: ${(props: { theme?: any }) =>
    props.theme.colors.decunifyOrange};
  opacity: 0.9;
  margin-top: 60px;
  margin-left: 100px;
  padding: 44px 23px 0px 41px;
  color: white;
  font-family: Roboto;
  font-size: 21px;
  line-height: 1.43;
`;

const styles = {
  Container,
  ContentWrapper,
  Content,
  ContentHeader,
  HeaderText,
  ContentTextWrapper
};

export default styles;
