import React, { Component } from "react";

import styles from "./styles";

interface ImageType {
  data: {
    name?: string;
    image: {
      url: string;
      alt: string;
    };
  };
}
interface IProps {
  logo?: ImageType;
  social?: ImageType;
  content: {
    data: {
      content: {
        html: string;
      };
      subtitle: string;
      title: string;
      background: {
        url: string;
      };
    };
  };
}

class Home extends Component<IProps> {
  render() {
    const {
      content: {
        data: {
          content: { html: content },
          subtitle,
          title,
          background: { url: background }
        }
      }
    } = this.props;

    const {
      Container,
      ContentWrapper,
      Content,
      ContentHeader,
      HeaderText,
      ContentTextWrapper
    } = styles;

    return (
      <Container id="home">
        <ContentWrapper background={background}>
          <Content>
            <ContentHeader>
              <HeaderText orange>{title}</HeaderText>
              <HeaderText>{subtitle}</HeaderText>
            </ContentHeader>
            <ContentTextWrapper dangerouslySetInnerHTML={{ __html: content }} />
          </Content>
        </ContentWrapper>
      </Container>
    );
  }
}

export default Home;
