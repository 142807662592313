import styled from "@emotion/styled";

const Padding = styled.div`
  padding-top: 50px;
  padding-bottom: 200px;
`;

const PolicyTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  color: #f38f1d;
  margin-bottom: 36px;
`;

const ContentTitle = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 6px;
`;

const ContentParagraph = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
`;

const styles = {
  Padding,
  PolicyTitle,
  ContentTitle,
  ContentParagraph
};

export default styles;
